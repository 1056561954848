/* eslint-disable @typescript-eslint/no-explicit-any */
import { PHONE_REGEXP, SHIPPING_METHOD } from '@/constants'
import * as Yup from 'yup'

export const translateSchema = (schemaName: string, translate?: any) => {
  let schemaValidation
  switch (schemaName) {
    case 'register':
      return Yup.object().shape({
        phone: Yup.string()
          .matches(PHONE_REGEXP, translate('phoneVldFomat'))
          .min(10, translate('phoneVldFomat'))
          .max(11, translate('phoneVldFomat'))
          .required(translate('required')),
        name: Yup.string().required(translate('nameRequired')),
        preference: Yup.boolean().isTrue(translate('preferenceRequired')),
      })
    case 'searchOrder':
      return Yup.object().shape({
        phone: Yup.string()
          .min(10, translate('phoneVldFomat'))
          .max(11, translate('phoneVldFomat'))
          .required(translate('required')),
      })
    case 'chooseDestination':
      return Yup.object().shape({
        provinceCode: Yup.string().required(translate('provinceRequired')),
        wardCode: Yup.string().required(translate('districtRequired')),
      })
    case 'contact':
      return Yup.object().shape({
        phone: Yup.string()
          .matches(PHONE_REGEXP, translate('phoneVldFomat'))
          .min(10, translate('phoneVldFomat'))
          .max(11, translate('phoneVldFomat'))
          .required(translate('required')),
        name: Yup.string().required(translate('nameRequired')),
      })
    case 'searchOrderOtp':
      return Yup.object().shape({
        otp: Yup.string()
          .min(4, translate('otpVldInvalid'))
          .max(4, translate('otpVldInvalid'))
          .required(translate('otpRequire')),
      })
    case 'otp':
      return Yup.object().shape({
        otp: Yup.string()
          .min(4, translate('otpVldInvalid'))
          .max(4, translate('otpVldInvalid'))
          .required(translate('otpRequire')),
      })
    case 'login':
      return Yup.object().shape({
        phone: Yup.string()
          .min(10, translate('phoneVldFomat'))
          .max(11, translate('phoneVldFomat'))
          .required(translate('required')),
      })
    case 'reviewProduct':
      return Yup.object().shape({
        point: Yup.number().required(translate('ratePointRequired')),
      })
    case 'checkoutCart':
      return Yup.object().shape({
        phone: Yup.string()
          .matches(PHONE_REGEXP, translate('phoneVldFomat'))
          .min(10, translate('phoneVldFomat'))
          .max(11, translate('phoneVldFomat'))
          .required(translate('phoneRequired')),
        name: Yup.string().required(translate('nameRequired')),
        preference: Yup.boolean(),
        alternateName: Yup.string().when(
          'preference',
          (preference, schema: any) => {
            if (preference[0])
              return schema.required(translate('alternateNameRequired'))
          }
        ),
        alternatePhone: Yup.string().when(
          'preference',
          (preference, schema: any) => {
            if (preference[0])
              return schema
                .matches(PHONE_REGEXP, translate('phoneVldFomat'))
                .min(10, translate('phoneVldFomat'))
                .max(11, translate('phoneVldFomat'))
                .required(translate('phoneRequired'))
          }
        ),
        invoiceName: Yup.string().when('vat', (vat, schema: any) => {
          if (vat[0]) return schema.required(translate('invoiceNameRequired'))
        }),
        invoiceAddress: Yup.string().when('vat', (vat, schema: any) => {
          if (vat[0])
            return schema.required(translate('invoiceAddressRequired'))
        }),
        invoiceTaxNumber: Yup.string().when('vat', (vat, schema: any) => {
          if (vat[0])
            return schema.required(translate('invoiceTaxNumberRequired'))
        }),
        invoiceEmail: Yup.string().when('vat', (vat, schema: any) => {
          if (vat[0]) return schema.required(translate('invoiceEmailRequired'))
        }),
        storeCode: Yup.string().when('shippingMethod', (item, schema: any) => {
          if (item[0] === SHIPPING_METHOD.PICKUP_IN_STORE) {
            return schema.required(translate('storeRequired'))
          }
        }),
        provinceCode: Yup.string().when(
          'shippingMethod',
          (item, schema: any) => {
            if (item[0] == SHIPPING_METHOD.STANDARD)
              return schema.required(translate('provinceRequired'))
          }
        ),
        districtCode: Yup.string().when(
          'shippingMethod',
          (item, schema: any) => {
            if (item[0] == SHIPPING_METHOD.STANDARD)
              return schema.required(translate('districtRequired'))
          }
        ),
        wardCode: Yup.string().when('shippingMethod', (item, schema: any) => {
          if (item[0] == SHIPPING_METHOD.STANDARD)
            return schema.required(translate('wardRequired'))
        }),
        address: Yup.string().when('shippingMethod', (item, schema: any) => {
          if (item[0] == SHIPPING_METHOD.STANDARD)
            return schema.required(translate('addressRequired'))
        }),
      })
    case 'checkoutCard':
      return Yup.object({
        cardName: Yup.string().required('Vui lòng nhập'),
        // cardNumber: Yup.string()
        //   .trim()
        //   .transform(value => value.replace(/\s/g, ''))
        //   .required('Vui lòng nhập thông tin thẻ')
        //   .matches(/^\d{16}$/, 'Số thẻ không hợp lệ'),
        expire: Yup.string()
          .required('Vui lòng nhập ngày hết hạn')
          .matches(/^\d{2}\/\d{4}$/, 'Ngày hết hạn không hợp lệ'),
        // cvv: Yup.string()
        //   .required('Vui lòng nhập CVV')
        //   .matches(/^\d{3}$/, 'CVV không hợp lệ'),
      })
    case 'updateInfo':
      return Yup.object().shape({
        name: Yup.string().required(translate('nameRequired')),
        provinceCode: Yup.string().required(translate('provinceRequired')),
        districtCode: Yup.string().required(translate('districtRequired')),
        wardCode: Yup.string().required(translate('wardRequired')),
        address: Yup.string().required(translate('addressRequired')),
      })
  }

  return schemaValidation
}
